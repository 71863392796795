.perPageFilter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pageSelect{
    margin: 0 5px;
}
.pageSelect .css-bg1rzq-control{
    min-height: 20px!important;
}

.pageSelect .css-16pqwjk-indicatorContainer{
    padding: 2px;
}
.pageSelect svg{
    height: 15px;
    width: 15px;
}

.perPageFilter .css-1hwfws3 {
    height: 25px !important;
    min-width: 29px !important;
    padding-left: 5px !important;
}

.pageSelect svg{
    height: 15px;
    width: 15px;
}
.pageSelect .css-1szy77t-control{
    min-height: 25px!important;
}
.main-section .main-inner.mychat .asc-accord-1 {
    background: #fff !important;
    height: auto !important;
}
.chat-user .chat-container div[role=complementary] {
    background-color: #fff;
}
