@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i,900,900i');
body {
	color: #787878;
	position: relative;
}
img {
	width: 100%;
}
html {
	height: 100%;
}
/******************** HEADER ***********************/

.login-bg {
	background: url(./background1.png) no-repeat;
	background-size: cover;
	height: 100vh;
	background-position: center;
}
.login-bg.bg2 {
	background: url(./background2.png) no-repeat;
	background-size: cover;
	height: 100vh;
	background-position: center;
}
.login-bg.bg3 {
	background: url(./background3.png) no-repeat;
	background-size: cover;
	height: 100vh;
	background-position: center;
}
.login-content {
	width: 50%;
	margin: 0px auto;
	text-align: center;
	padding-top: 12%;
}
.logo-box img {
	width: auto;
}
.logo-box {
	margin-bottom: 60px;
}
h4.login-title {
	font-size: 15px;
	color: #fff;
	margin-bottom: 44px;
	font-weight: 300;
}
input.custom-input {
	width: 100%;
	margin-bottom: 20px;
	padding: 10px;
	background: transparent;
	border: 0px;
	border-bottom: 2px solid #fff;
	color: #fff;
	outline: none;
}
form.login-form p label {
	font-size: 14px !important;
	font-weight: 400 !important;
	padding-top: 2px;
	color: #fff !important;
}
button.custom-btn {
	background: #f55a57;
	color: #fff;
	border: none;
	min-width: 190px;
	padding: 8px 6px;
	border-radius: 100px;
	outline: none;
}
form.login-form p {
	text-align: right;
}
.f-footer {
	margin-top: 60px;
}
.login-content.split-content {
	width: 100%;
	margin: 0 0;
	padding-top: 0px;
	height: 100vh;
}
.lft-panel {
	width: 50%;
	float: left;
}
.lft-img img {
	width: auto;
}
.rgt-panel {
	margin-left: 50%;
}
.logo-box {
	margin-bottom: 60px;
}
.container-fluid.pd0 {
	padding: 0;
	overflow: hidden;
}
.rgt-panel {
	margin-left: 50%;
	margin-top: -10px;
	background: rgba(0, 23, 43, 0.9);
	height: 100%;
}

.bot-frame2 {
	background: #fff;
	border-radius: 5px;
}
.img-left {
	width: 90px;
	height: 90px;
	border-radius: 100%;
	background: #f55a57;
	overflow: hidden;
	padding: 7px 21px;
	float: left;
}
.description {
	margin-left: 100px;
	padding-left: 17px;
}
.description h4 {
	color: #333;
}
.bot-selection {
	text-align: center;
	width: 60%;
	margin: 80px auto;
}
.bot-frame2 {
	background: #fff;
	border-radius: 5px;
	text-align: left;
	padding: 10px;
	overflow: hidden;
}
.bot-list ul {
	list-style: none;
}
.bot-list ul li {
	margin-bottom: 22px;
}
.bot-frame2:hover {
	transition: all 0.3s;
	transform: scale(1.05);
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 24px 0px rgba(245, 90, 87, 1);
	-moz-box-shadow: 0px 0px 24px 0px rgba(245, 90, 87, 1);
	box-shadow: 0px 0px 24px 0px rgba(245, 90, 87, 1);
}

/***** css for new signup page*****/

/* css update*/

.custom-header .nav {
	padding: 0 15px;
}
.custom-header a.nav-brand {
	width: 280px;
}
.custom-header a.nav-brand img {
	width: 100%;
}
.nav-cta li {
	display: inline-block;
	padding: 0 10px;
	font-size: 26px;
	color: #000;
}
.nav-cta li a {
	color: #000;
}
a.help-center {
	padding: 11px 24px;
	vertical-align: middle;
	border-radius: 100px;
}
a.help-center.active {
	background: #ff5a54;
	color: #fff !important;
}
header.custom-header {
	padding: 32px 0;
}
footer {
	background: #192a5c;
	color: #fff;
}
.nav-right.pull-right {
	margin-top: 12px;
}
.foot-cards h4 {
	font-size: 18px !important;
	font-weight: 400;
}
.foot-cards p {
	font-size: 16px;
	font-weight: 200;
}
ul.social-links {
	padding-left: 0;
}
.social-links li {
	display: inline-block;
	text-align: left;
}
.social-links li:first-child {
	padding-right: 6px;
}
.foot-cards p a {
	color: #fff;
	font-size: 20px;
}
ul.explore-list {
	padding-left: 24px;
	font-size: 16px;
	color: #fff;
}
ul.explore-list li a {
	color: #fff;
}
.explore-list li {
	list-style: none;
	position: relative;
}
.explore-list li:after {
	position: absolute;
	background: #259abb;
	width: 10px;
	height: 10px;
	content: '';
	left: -22px;
	top: 5px;
}
ul.news {
	padding-left: 0;
}
ul.news li {
	list-style: none;
}
.date {
	width: 50px;
	float: left;
	color: #259abb;
}
.date h4 {
	font-weight: 500;
	font-size: 20px !important;
	margin: 0;
}
.news-info {
	margin-left: 50px; /* margin-top: 8px; */
}
footer.custom-footer {
	padding: 32px 21px;
}
.foot-cards h4 {
	font-size: 18px !important;
	font-weight: 400;
	margin-bottom: 18px;
}
ul.social-links {
	padding-left: 0;
	margin-top: 24px;
}
.main-frame {
	width: 100%;
	margin: 102px 0;
	background: #142e50;
	border: 1px solid;
	border-radius: 10px;
	position: relative;
	margin-top: 45px;
}
.switch-indicator.for-other-step {
	bottom: 12px;
	left: 43%;
	text-align: center;
	margin-top: 20px;
	display: block;
}
.switch-indicator {
	display: none;
}
.bot-static {
	position: relative;
	bottom: 11px;
	width: 84%;
	right: -29px;
}

.orange-main {
	background: #f45d59;
	width: 51%;
	position: relative;
	border-radius: 10px;
	top: -25px;
	left: 20px;
	padding: 69px 86px;
	text-align: center;
	float: left;
}
.o-heading h4 {
	font-size: 24px;
	color: #fff;
	font-weight: 500;
	margin-bottom: 38px;
	text-align: center;
	text-transform: uppercase;
}
.o-error {
	font-size: px;
	color: red;
	font-weight: 500;
	margin-bottom: 38px;
	text-align: center;
	text-transform: uppercase;
}
.orange-main img {
	width: 42%;
	margin: 0px auto;
}
.m-rem {
	margin: 0px;
}
.oh-b h4 {
	margin-top: 38px;
}
.circle-border {
	width: 12px;
	height: 12px;
	border: 1px solid #fff;
	border-radius: 100%;
	display: inline-block;
}
.circle-border.active {
	border-color: #142e50;
	border-width: 1.5px;
}
.switch-indicator li {
	display: inline-block;
	padding: 0 2px;
}
.blue-frame {
	height: 540px;
	position: relative;
}
.form-content {
	margin-left: 49%;
	padding-left: 64px;
}
.card.form-card {
	box-shadow: none !important;
}
.card.form-card {
	padding: 0 27px;
	padding-left: 0;
	text-align: center;
}
.md-form label {
	-webkit-transition: 0.2s ease-out;
	transition: 0.2s ease-out;
	color: #757575;
	position: absolute;
	top: 0.8rem;
	left: 0;
	font-size: 15px;
	cursor: text;
	width: 100%;
	font-weight: 600;
}
.md-form {
	margin-bottom: 36px;
}
.md-form label {
	-webkit-transition: 0.2s ease-out;
	transition: 0.2s ease-out;
	color: #787a7c;
	position: absolute;
	top: 3px;
	left: 0;
	font-size: 15px;
	cursor: text;
	width: 100%;
	font-weight: 600;
}
input[type='date']:focus:not([readonly]),
input[type='datetime-local']:focus:not([readonly]),
input[type='email']:focus:not([readonly]),
input[type='number']:focus:not([readonly]),
input[type='password']:focus:not([readonly]),
input[type='search-md']:focus:not([readonly]),
input[type='search']:focus:not([readonly]),
input[type='tel']:focus:not([readonly]),
input[type='text']:focus:not([readonly]),
input[type='time']:focus:not([readonly]),
input[type='url']:focus:not([readonly]),
textarea.md-textarea:focus:not([readonly]) {
	border-bottom: 1px solid #f45d59;
	box-shadow: 0 1px 0 0 #f45d59;
}
.md-form label.active {
	font-size: 12px;
	color: #f45d59 !important;
}
.md-form input {
	font-size: 17px;
	text-align: center;
	color: #fff !important;
}
.btn-custom button.btn {
	font-size: 16px;
}
.bl-bg {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00aadf+0,0088b8+46 */
	background: #00aadf; /* Old browsers */
	background: -moz-linear-gradient(-45deg, #00aadf 0%, #0088b8 46%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, #00aadf 0%, #0088b8 46%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #00aadf 0%, #0088b8 46%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00aadf', endColorstr='#0088b8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.btn-default.active,
.btn-default:active,
.btn-default:focus {
	background-color: #142e50 !important;
}
.custom-check label {
	font-size: 17px;
	color: #74777a;
}
.custom-check label {
	font-size: 14px;
	color: #74777a;
	font-weight: 600;
}
.custom-check {
	margin-bottom: 20px;
}
p.tc {
	color: #707478;
	text-align: center;
	line-height: 17px;
	padding: 30px 0;
	padding-right: 20px;
}
.switch-indicator {
	position: absolute;
	bottom: -21px;
	left: 158px;
}
.styled-checkbox + label:before {
	background: transparent !important;
	border: 2px solid #00aadf;
	border-radius: 4px;
}
.dropdown.show-info {
	position: absolute;
	right: 0;
	top: 0;
}
.dropdown.show-info img {
	width: 16px;
}
.dropdown.show-info {
	cursor: pointer;
}
.dropdown.show-info .dropdown-menu {
	width: 256px;
	text-align: center;
	padding: 0 0; /* right: 34px !important; */
	position: relative;
	top: 27px;
	left: 19px;
	color: #fff;
	background: #f45d59;
}
.dropdown.show-info .dropdown-menu li {
	margin: 8px 0;
}
.efl-main {
	width: 50%;
	margin: 0px auto;
	background: #f45d59;
	margin-top: 13%;
	border-radius: 20px;
	min-height: 305px;
	padding: 0 45px;
}
.agent-setup-img {
	position: relative;
	width: 149px;
	display: inline-block;
	text-align: center;
	width: 100%;
	margin-top: -70px;
}
.agent-setup-img img {
	width: 167px;
}
.efl-main h4 {
	color: #fff;
	text-align: center;
	margin-top: 28px;
	text-transform: uppercase;
	font-size: 20px;
}
.efl-main p {
	text-align: center;
	color: #fff;
	font-weight: 400;
	margin-top: 33px;
	font-size: 14px;
}
.switch-indicator.for-other-step {
	bottom: 12px;
	left: 43%;
}
.switch-indicator.for-other-step .circle-border.active {
	border-color: #f45d58;
	border-width: 1.5px;
}
.nav-control.clearfix {
	position: absolute;
	bottom: 25px;
	width: 100%;
	padding: 0 26px;
	color: #fff;
}
.nav-control a {
	color: #fff;
}
.nav-control a:hover {
	color: #f45d58;
	transition: all 0.5s;
}
.empty-frame {
	height: 230px;
	position: absolute;
	width: 100px;
	background: #f45d58;
	top: 29%;
	opacity: 0.8;
}
.empty-frame.efl {
	left: 0;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}
.empty-frame.efr {
	right: 0;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}
.info-bot-img img {
	width: 60px;
}
.info-avt {
	position: absolute;
	color: #fff;
	font-size: 10px !important;
	width: 112px;
	text-align: center;
	padding: 15px 14px;
	background: #f45d58;
	border-radius: 4px;
	left: 59px;
	top: -7px;
}
.info-avt h4 {
	font-size: 14px;
	border-bottom: 1px solid #fff;
	padding-bottom: 9px;
}
.info-bot-img {
	margin-bottom: 11px;
}
.qa-content {
	margin-left: 22%;
	padding: 53px 0px;
	padding-right: 50px;
}
ul.qlist {
	padding-left: 0;
	padding-right: 5px;
}
.qlist li {
	list-style: none;
}
h5.q-m {
	color: #fff;
}
p.ans-m {
	color: #ceebf8;
}
.qa-box {
	height: 320px;
	overflow: auto;
}
.qlist li {
	list-style: none;
	margin-bottom: 38px;
}
.add-info {
	color: #fff;
	padding-top: 40px;
}
.add-info p {
	position: relative;
}
.add-info p:after {
	background: url(./info-right.png);
	height: 36px;
	position: absolute;
	content: '';
	width: 36px;
	left: -53px;
	top: 3px;
}
.basic-setup h3 {
	text-transform: uppercase;
	color: #fff;
	line-height: 30px;
	font-size: 18px;
}
.info-avt h4 {
	font-size: 14px;
	border-bottom: 1px solid #fff;
	padding-bottom: 9px;
	word-wrap: normal;
	word-break: break-word;
}
.try-out-links img {
	width: 16px; /* padding-right: 10px; */
	margin-right: 7px;
}
.try-out-links a {
	display: block;
	width: 40%;
	text-align: center;
	background: red;
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 5px 0;
	color: #fff;
}
.orange {
	background-color: #f45d59 !important;
}
.green {
	background-color: #72b431;
}

.try-out-links a:hover {
	opacity: 0.8;
	transform: all 0.4s;
}

ul.color-pickers {
	padding-left: 0;
	display: inherit;
}
ul.color-pickers li {
	display: inline-block;
	list-style: none;
	color: #fff;
	vertical-align: middle;
	padding: 0 12px;
}
.patch-frame {
	background: red;
	width: 20px;
	height: 12px;
	display: inline-block;
}
.color-patch span:hover {
	cursor: pointer;
}
.cp1 {
	background-color: #e4f7f5;
}
.cp2 {
	background-color: #781f70;
}
.cp3 {
	background-color: #d85f83;
}
.cp4 {
	background-color: #ffffff;
}
.cp5 {
	background-color: #7a7d8b;
}
.cp6 {
	background-color: #032247;
}
.cp7 {
	background-color: #ffbe53;
}
.cp8 {
	background-color: #29abe2;
}
.cp9 {
	background-color: #ffffff;
}
.cp10 {
	background-color: #7a7d8b;
}

.cp11 {
	background-color: #262e5d;
}
.cp12 {
	background-color: #a43a05;
}
.cp13 {
	background-color: #c75f35;
}
.cp14 {
	background-color: #f2f2f2;
}
.cp15 {
	background-color: #ffffff;
}
.cp16 {
	background-color: #262e5d;
}
.cp17 {
	background-color: #29abe2;
}
.cp18 {
	background-color: #7a7d8b;
}
.cp19 {
	background-color: #ff6a33;
}
.cp20 {
	background-color: #fbfbfb;
}

.s-nav-bar div {
	display: inline-block;
}
.nav-panel img {
	width: 90px;
}
.hambug img {
	width: 13px;
}
.nav-panel {
	width: 16%;
	float: left;
}
.url-panel {
	float: left;
	width: 79%;
}
.hambug {
	float: left;
	width: 5%;
	text-align: right;
}
input.cust-url {
	background: #fff;
	border-radius: 4px;
	padding: 1px 10px;
	width: 98%;
}
.s-nav-bar {
	background: #262e5d;
	padding: 4px 9px;
}
.dummy-header.clearfix {
	color: #fff;
}
.edit-ico img {
	width: 16px;
}
.bot-name {
	text-align: right;
}
.main-page h4 {
	display: inline-block;
	vertical-align: middle;
}
span.dropdown.edit-ico {
	display: inline-block;
}
span.dropdown.edit-ico {
	display: inline-block;
	padding: 0 8px;
}
section.banner-title {
	text-align: center;
}
.page-content h3 {
	display: inline-block;
}
.page-content h3 {
	display: inline-block;
	font-size: 20px;
}
.bot-info-plus {
	position: relative;
}
span.dropdown.bot-info-edit {
	position: absolute;
	right: -23px;
	top: 22px;
}
.bot-min-info {
	position: relative;
}
span.dropdown.min-info-edit {
	position: absolute;
	top: 0;
	right: -22px;
}
.main-page p {
	color: #7a7d8b;
}
.trending-ques h3:after {
	background: url(./info-right.png);
	height: 26px;
	position: absolute;
	content: '';
	width: 26px;
	left: -24px;
	top: 30px;
	background-size: 100%;
}
.bot-static img {
	width: 100%;
}

span.dropdown.pure-bot-edit {
	right: 4px;
	position: absolute;
	right: -10px;
	top: 60px;
}
.upload-img-frame img {
	width: 112px;
	margin: 0px auto;
}
.bot-logo-edit .dropdown-menu {
	padding: 20px 20px;
	background-color: #8b61b4;
	text-align: center;
	width: 215px;
	animation: bubble 0.2s ease-in-out;
}
.bot-logo-edit {
}
.upload-custom {
	position: relative;
}
.outer-frame {
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 2px;
	padding: 4px 0;
}
input.custom-up {
	width: 100%;
	position: absolute;
	top: -1px;
	opacity: 0;
}
button.upload-btn {
	border-radius: 2px;
	background: #ffbf4c;
	border: navajowhite;
	min-width: 84px;
	padding: 4px 8px;
	margin-top: 18px;
}
@-webkit-keyframes bubble {
	0% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	100% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.banner-content-edit .dropdown-menu {
	padding: 20px 20px;
	background-color: #3ba9ce;
	text-align: center;
	width: 240px;
	animation: bubble 0.2s ease-in-out;
}
.line-inputs {
	margin-bottom: 18px;
}
.line-inputs input {
	text-align: center;
	color: #fff !important;
	border-color: #fff;
	font-size: 13px;
}
textarea.screen-content {
	min-height: 73px;
	border: none;
	background-color: rgba(255, 255, 255, 0.4);
	resize: none;
	padding: 10px 10px;
}
.banner-content-edit h5,
.banner-content-edit p {
	color: #142e50;
}
.submit-btn button {
	border-radius: 2px;
	background: #e46059;
	border: navajowhite;
	min-width: 84px;
	padding: 4px 8px;
	margin-top: 18px;
}
.line-inputs ::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #fff;
	opacity: 1; /* Firefox */
}
.banner-content-edit h5 {
	font-size: 16px;
}
.bot-name-edit .dropdown-menu {
	padding: 20px 20px;
	background-color: #3ba9ce;
	text-align: center;
	width: 313px;
	animation: bubble 0.2s ease-in-out;
}
.vera-change {
	text-align: center;
}
.bot-info-change input.fill-input {
	min-width: 122px;
	border: none;
	background: #e46059;
	padding: 4px 8px;
	text-align: center;
	color: #fff;
	border-radius: 2px;
}
.bot-info-change ::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #fff;
	opacity: 1; /* Firefox */
}
.main-page {
	background: #032247;
	padding: 0px 20px;
}
span.close-popup {
	position: absolute;
	right: 10px;
	top: 4px;
}

span.close-modal.close img {
	width: 26px; /* opacity: 1 !important; */
}
span.close-modal.close {
	position: absolute;
	top: 12px;
	right: -9px;
	width: 50px;
	z-index: 8;
	opacity: 1 !important;
}
.qa-box .mCSB_dragger_bar {
	background-color: #060014 !important;
}

.hm-gradient {
	background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
}
.darken-grey-text {
	color: #2e2e2e;
}
.danger-text {
	color: #ff3547;
}
.default-text {
	color: #2bbbad;
}
.info-text {
	color: #33b5e5;
}
.form-white .md-form label {
	color: #fff;
}
.form-white input[type='text']:focus:not([readonly]) {
	border-bottom: 1px solid #fff;
	-webkit-box-shadow: 0 1px 0 0 #fff;
	box-shadow: 0 1px 0 0 #fff;
}
.form-white input[type='text']:focus:not([readonly]) + label {
	color: #fff;
}
.form-white input[type='password']:focus:not([readonly]) {
	border-bottom: 1px solid #fff;
	-webkit-box-shadow: 0 1px 0 0 #fff;
	box-shadow: 0 1px 0 0 #fff;
}
.form-white input[type='password']:focus:not([readonly]) + label {
	color: #fff;
}
.form-white input[type='password'],
.form-white input[type='text'] {
	border-bottom: 1px solid #fff;
}
.form-white .form-control:focus {
	color: #fff;
}
.form-white .form-control {
	color: #fff;
}
.form-white textarea.md-textarea:focus:not([readonly]) {
	border-bottom: 1px solid #fff;
	box-shadow: 0 1px 0 0 #fff;
	color: #fff;
}
.form-white textarea.md-textarea {
	border-bottom: 1px solid #fff;
	color: #fff;
}
.form-white textarea.md-textarea:focus:not([readonly]) + label {
	color: #fff;
}
.ripe-malinka-gradient {
	background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
}
.near-moon-gradient {
	background-image: linear-gradient(to bottom, #5ee7df 0%, #b490ca 100%);
}
h3.trend-h {
	margin: 0px 0px 13px 0px;
}
.page-content h3 {
	display: inline-block;
	font-size: 17px;
}
.color-theme-selection {
	padding-top: 24px;
}
.qa-content.bot-setup {
	padding-top: 25px;
}
.bot-min-info p {
	padding: 0 0;
	margin: 24px 0;
}
section.trending-ques {
	padding-left: 25px;
}
.carousel.slide {
	height: 134px;
}
.carousel-indicators {
	position: static !important;
	right: 0;
	width: 100%;
	left: 8px;
	margin: 0px auto !important;
}
.bot-select li {
	display: inline-block;
	width: 32%;
}
.bot-select {
	padding-left: 0px;
}
.bot-out img {
	width: 30px;
}
.bot-avatar-switch li.active .bot-out img {
	width: 48px;
	margin-top: -10px;
}
.bot-avatar-switch {
	margin: 24px 0;
}
.bot-avatar-switch li.active .bot-out {
	background: #fff;
	border-radius: 100px;
	height: 100px;
	width: 100px;
	padding: 17px 0;
	border: 12px solid #2d94c2;
	transition: all 0.4s ease-in-out;
}
.bot-out {
	height: 100px;
	padding: 30px 0;
}
.nav-control {
	display: none;
}
.show-all {
	display: block !important;
}
.hide {
	display: none;
}
.hidden-text {
	display: hidden;
}
.url-panel {
	position: relative;
}
span.dropdown.website-url {
	position: absolute;
	right: 0;
	top: 0;
}
.website-url .dropdown-menu {
	padding: 20px 20px;
	background-color: #8b61b4;
	text-align: center;
	width: 300px;
	animation: bubble 0.2s ease-in-out;
	left: 227px;
	color: #fff;
}
input.web-url {
	background-color: rgba(255, 255, 255, 0.5);
	padding: 3px 10px;
	border-radius: 4px;
	font-size: 14px;
	width: 93%;
}
.website-url ::placeholder {
	color: #fff;
}
p.ans-m {
	color: #ceebf8;
	font-size: 11px;
}

/** FOR checkboxes**/
.styled-checkbox {
	position: absolute;
	opacity: 0;
}
.styled-checkbox + label {
	position: relative;
	cursor: pointer;
	padding: 0;
}
.styled-checkbox + label:before {
	content: '';
	margin-right: 10px;
	display: inline-block;
	vertical-align: text-top;
	width: 20px;
	height: 20px;
	background: white;
}
.styled-checkbox:hover + label:before {
	background: #f35429;
}
.styled-checkbox:focus + label:before {
	-webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
	background: #f35429;
}
.styled-checkbox:disabled + label {
	color: #b8b8b8;
	cursor: auto;
}
.styled-checkbox:disabled + label:before {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: #ddd;
}
.styled-checkbox:checked + label:after {
	content: '';
	position: absolute;
	left: 5px;
	top: 9px;
	background: white;
	width: 2px;
	height: 2px;
	-webkit-box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
	box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.new-signup .main-frame {
	width: 100%;
	margin: 0;
	background: transparent;
	border: 0px;
	border-radius: 0px;
	position: relative;
	margin-top: 0;
	margin-top: 110px;
}
.new-signup .nav-cta li {
	display: inline-block;
	padding: 0 10px;
	font-size: 15px;
	color: #fff !important;
	font-weight: 400;
}
.new-signup .nav-cta li a {
	color: #fff;
}
.new-signup a.help-center:hover {
	background: #ff5a54;
	color: #fff !important;
	transition: all 0.4s;
}
.new-signup .custom-header a.nav-brand {
	width: 208px;
}
.new-signup .orange-main {
	background: transparent;
	width: 51%;
	position: relative;
	border-radius: 0;
	top: 0;
	left: 0;
	padding: 45px 76px;
	text-align: center;
	float: left;
	padding-top: 5px;
}
.new-signup .o-heading h4 {
	font-size: 20px;
	color: #fff;
	font-weight: 500;
	margin-bottom: 30px;
	margin-top: 0;
}
.new-signup footer.custom-footer {
	padding: 32px 21px;
	position: static;
	bottom: 0;
	left: 0;
	right: 0;
}
.new-signup .orange-main img {
	width: 112px;
	margin: 0px auto;
}
.new-signup .custom-check label {
	color: #fff !important;
	text-align: left;
}
.new-signup .custom-check label a {
	color: #c4c4c4 !important;
}
.new-signup .form-content {
	margin-left: 54%;
	padding: 40px 22px;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	padding-bottom: 0px;
}
.new-signup ul.signup-fl {
	color: #fff;
	text-align: left;
	font-style: normal;
	list-style: none;
	margin-top: 37px;
	padding-left: 48px;
}
.new-signup ul.signup-fl li {
	margin-bottom: 13px;
}
.new-signup .oh-b.m-rem h4 {
	font-size: 17px;
}
.new-signup ul.signup-fl li:before {
	background: url(./checked.png);
	position: absolute;
	height: 10px;
	width: 10px;
}
.new-signup ul.signup-fl li:before {
	position: absolute;
	background: url(./checked.png);
	height: 18px;
	width: 18px;
	content: '';
	left: -29px;
	top: 3px;
	background-size: 100%;
}
.new-signup ul.signup-fl li {
	margin-bottom: 13px;
	position: relative;
	font-size: 15px;
}
.new-signup .btn-custom button.btn {
	font-size: 14px;
	background: #ff5a54;
	border-radius: 50px;
}
.new-signup .efl-main {
	width: 80%;
	margin: 0px auto;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 20px;
	min-height: 305px;
	padding: 0 45px;
	padding-bottom: 20px;
}
.new-signup .agent-setup-img img {
	width: 60px;
}
.new-signup .qa-content {
	margin-left: 0;
	padding: 53px 0px;
	padding-right: 0;
}
.new-signup .qa-content {
	margin-left: 0;
	padding: 23px 0px;
	padding-right: 0;
}
.new-signup .empty-frame {
	height: 230px;
	position: absolute;
	width: 39px;
	background: rgba(0, 0, 0, 0.6);
	top: 29%;
	opacity: 0.8;
}
.new-signup p.ans-m {
	color: #ceebf8;
	font-size: 13px;
	color: #fff;
	text-align: left;
	margin-top: 0;
}
.new-signup .qa-content {
	margin-left: 0;
	padding: 23px 0px;
	padding-right: 0;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 6px;
	padding: 13px 0px;
	padding-left: 19px;
	margin-top: 20px;
}
.new-signup .switch-indicator.for-other-step {
	position: static;
}
.new-signup .add-info p:after {
	left: 0;
	top: 3px;
}
.new-signup .qa-content.bot-setup {
	padding: 20px 20px;
}
.new-signup .step3.steps.bot-config.clearfix {
	margin-top: -53px;
}
.new-signup .website-url .dropdown-menu {
	padding: 20px 20px;
	background-color: #8b61b4;
	text-align: center;
	width: 500px;
	animation: bubble 0.2s ease-in-out;
	left: -462px;
	color: #fff;
	margin-top: px;
}
.new-signup input.cust-url {
	background: #fff;
	border-radius: 4px;
	padding: 1px 10px;
	width: 98%;
	font-size: 14px;
	padding: 6px 12px;
}
.new-signup .s-nav-bar {
	background: #262e5d;
	padding: 4px 9px;
	height: 53px;
}
.new-signup .nav-panel {
	padding-top: 10px;
}
.new-signup .url-panel {
	padding-top: 5px;
}
.new-signup span.dropdown.website-url {
	position: absolute;
	right: 0;
	top: 10px;
}
.new-signup .edit-ico img {
	width: 22px;
}
.new-signup .bot-domian h4 {
	font-size: 21px;
	text-transform: uppercase;
}
.new-signup .bot-domian {
	color: #fff;
	text-align: center;
}
.new-signup .setup-progress h4 {
	font-size: 22px;
}
.new-signup .setup-progress {
	text-align: center;
	color: #fff;
}
.new-signup .loading-progress img {
	width: 18%;
	margin: 53px auto; /* display: inline-table; */
}
.new-signup .loading-progress {
	text-align: center;
	color: #fff;
}
.new-signup .oh-b.m-rem h4 {
	font-size: 17px;
	margin-bottom: 40px;
}
.new-signup .laoding-msg p {
	font-size: 19px;
}
.new-signup p.ps-require {
	text-align: left;
}
.new-signup .card.form-card {
	position: relative;
	padding: 0 4px;
	padding-left: 0;
	text-align: center;
	top: -18px;
}
.new-signup .hr-agent-setup .qa-content {
	background: transparent;
}
.new-signup .hr-agent-setup .add-info {
	color: #fff;
	padding-top: 0;
	background: rgba(255, 255, 255, 0.1);
	margin: 0px auto;
	width: 82%;
	padding: 7px 15px;
	border-radius: 5px;
	margin-top: 24px;
}
.add-info p {
	position: relative;
	margin-top: 0;
	margin: 0;
}
.new-signup .add-info p:after {
	left: -65px;
	top: 1px;
}
.new-signup .ps-require ul {
	padding-left: 0;
	list-style: none;
	text-align: left;
	padding-left: 2px;
}
.new-signup span.min-check {
	display: inline-block;
	width: 14px;
	margin-left: 5px;
	display: none;
}
.new-signup li.ps-check {
	color: #fff;
}
.new-signup li.ps-check span.min-check {
	display: inline-block !important;
}
.new-signup section.page-content {
	min-height: 310px;
}
.new-signup .url-input {
	width: 80%;
	margin: 0px auto;
	padding-top: 11%;
}
.new-signup input.url-custom {
	width: 75%;
	border: 0px solid;
	padding: 8px 14px;
	height: 42px;
	border-radius: 4px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0;
	font-size: 16px;
	float: left;
	color: #333;
}
.new-signup button.url-btn {
	display: block;
	margin-left: 75%;
	width: 25%;
	height: 42px;
	border: 0px solid;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	font-size: 16px;
	background: #ff5a54;
}
.new-signup .panel-separation.clearfix h3 {
	color: #fff;
	font-size: 17px;
	text-align: left;
	margin-bottom: 26px;
}
.new-signup ul.sm-question {
	padding-left: 0;
	color: #fff;
	list-style: none;
}
.new-signup ul.sm-question li {
	margin-bottom: 8px;
	color: #c3c3c3;
	position: relative;
}
.new-signup .final-step .knowledge-base-setup h4 {
	margin-bottom: 23px;
}
.new-signup .try-out-links a {
	display: block;
	width: 68%;
}
.new-signup .try-out-links a {
	min-width: 174px;
	display: inline-block;
	width: auto;
	margin: 0px;
	padding: 6px 9px;
}
.new-signup .panel-right {
	margin-left: 51%;
}
.new-signup ul.sm-question {
	padding-left: 0;
	color: #fff;
	list-style: none;
	min-height: 149px;
	vertical-align: top;
}
.new-signup .panel-right ul.sm-question li:before {
	position: absolute;
	background: #37ce6b;
	content: '';
	left: 0;
	height: 10px;
	width: 10px;
	top: 5px;
	border-radius: 50px;
}
.new-signup .panel-left {
	width: 50%;
	float: left;
	position: relative;
}
.new-signup .panel-right ul.sm-question li {
	padding-left: 18px;
}
.new-signup .panel-left .try-out-links {
	padding-top: 18px;
}
.new-signup .custom-check {
	margin-bottom: 5px;
}
.new-signup .steps.final-step.efl-main.clearfix {
	padding: 35px 45px;
}
.new-signup .panel-left:after {
	position: absolute;
	content: '';
	width: 1px;
	background: #989898;
	height: 152px;
	right: 37px;
	top: 38px;
}
.new-signup .sp-note a {
	padding-left: 12px;
}
.new-signup .login-frame.fp h4.login-title {
	font-size: 23px !important;
}
.new-signup .dummy-captcha.clearfix {
	text-align: left;
}
.new-signup .dummy-captcha.clearfix img {
	width: 260px;
}
p.fp-instruction {
	text-align: center !important;
	color: #fff;
}
.ps-require li {
	text-transform: capitalize;
}
ul.switch {
	padding-left: 0;
}

.error-msg-n img {
	width: 49px;
	margin: 0px auto;
	display: block;
}
.error-msg-n {
	text-align: center;
	color: #333;
	font-size: 16px;
	padding: 15px 10px;
	border-radius: 10px !important;
	overflow: hidden;
	background: #fff;
}
.error-msg-n img {
	width: 49px;
	margin: 0px auto;
	display: block;
	margin-bottom: 15px;
}
.modal-open .new-signup .login-help .custom-check label {
	color: #333 !important;
}
.error-msg-n .custom-check {
	margin-bottom: 0;
}
.login-help {
	margin-top: 11px;
}
button.custom-btn.btn-gst {
	background: transparent;
	border: 1px solid;
	margin-left: 31px;
}
span.site-text {
	color: #fff;
}
.new-signup .md-form {
	margin-bottom: 8px;
}
.new-signup .md-form input {
	font-size: 14px;
	text-align: center;
	color: #fff !important;
	width: 96%;
	margin-bottom: 0;
	border-bottom: 1px solid;
}
.mdc-form.md-split-form.clearfix input {
	width: 42%;
}
.new-signup .oh-b.m-rem h4 {
	font-size: 17px;
	margin-bottom: 16px;
}

.ps-require {
	margin-bottom: 26px;
}
label.custom-label.active {
	text-align: left;
	display: block;
	font-size: 13px;
}
.mdc-form input {
	border: 1px solid #989898;
	border-radius: 5px;
	padding: 7px 9px;
	width: 95%;
	font-size: 14px;
}
.mdc-form.for-website input {
	width: 60%;
	float: left;
}
.mdc-form.for-website input {
	width: 70%;
	float: left;
}
span.site-text {
	color: #fff;
	margin-top: 11px;
	display: block;
	margin-left: 75%;
	text-align: left;
	/* padding-left: 11px; */
}
.new-signup .blue-frame {
	height: auto;
	position: static;
}
.new-signup .step1 {
	margin-top: -80px;
}
.login-bg.bg2 {
	background: url(./background2.png) no-repeat;
	background-size: cover;
	height: 100vh;
	background-position: center;
	overflow: auto;
	overflow: auto;
	overflow-x: hidden;
}
.mdc-form input {
	border: 1px solid #989898;
	border-radius: 5px;
	padding: 4px 9px;
	width: 95%;
	font-size: 13px;
	margin-bottom: 10px;
}
.ps-require li {
	display: block;
	margin-right: 10px;
}
.new-signup .custom-check label {
	font-size: 13px !important;
}
.new-signup .qlist li {
	list-style: none;
	margin-bottom: 23px;
}
.new-signup .qa-box {
	height: 285px;
	overflow: auto;
}
.form-md-wraper.clearfix .mdc-form {
	width: 45%;
	float: left;
	margin-right: 8%;
}
.form-md-wraper.clearfix .mdc-form:last-child {
	margin-right: 0px;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
	position: absolute;
	left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
}
[type='radio']:checked + label:before,
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 22px;
	height: 22px;
	border: 2px solid #0762e5;
	border-radius: 100%;
	background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
	content: '';
	width: 12px;
	height: 12px;
	background: #0762e5;
	position: absolute;
	top: 5px;
	left: 5px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
[type='radio']:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

/* Base for label styling */
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
	position: absolute;
	left: -9999px;
}
[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-weight: 500;
	font-size: 15px;
	color: #0762e5;
}

[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 22px;
	height: 22px;
	border: 1px solid #f55a57;
	background: #fff;
	border-radius: 0;
	box-shadow: none;
	/* padding-right: 10px; */
	background: transparent;
	border-radius: 3px;
}
/* checked mark aspect */
[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after {
	content: '\2713\0020';
	position: absolute;
	top: 0.15em;
	left: 0.22em;
	font-size: 1.3em;
	line-height: 0.8;
	color: #f55a57;
	transition: all 0.2s;
	font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
}
/* checked mark aspect changes */
[type='checkbox']:not(:checked) + label:after {
	opacity: 0;
	transform: scale(0);
}
[type='checkbox']:checked + label:after {
	opacity: 1;
	transform: scale(1);
}
/* disabled checkbox */
[type='checkbox']:disabled:not(:checked) + label:before,
[type='checkbox']:disabled:checked + label:before {
	box-shadow: none;
	border-color: #bbb;
	background-color: #ddd;
}
[type='checkbox']:disabled:checked + label:after {
	color: #999;
}
[type='checkbox']:disabled + label {
	color: #aaa;
}

@media all and (max-width: 1367px) {
	.pe_main .banner-top {
		background-size: cover;
		padding: 38px 0px 100px 0px !important;
	}
	.bot-selection {
		text-align: center;
		width: 60%;
		margin: 42px auto;
	}
	.img-left {
		width: 76px;
		height: 76px;
		float: left;
	}
	.description {
		margin-left: 75px;
		padding-left: 17px;
	}
	.new-signup .step1 {
		margin-top: -75px;
	}

	.after-reg-steps {
		margin-top: -78px;
	}
	.login-bg.bg2 {
		overflow: auto;
		overflow-x: hidden;
	}
	.new-signup .main-frame {
		margin-top: 96px;
	}
	.new-signup .qa-box {
		height: 240px;
		overflow: auto;
	}
	.knowledge-base-setup h4 {
		margin-top: 0px;
	}
	.new-signup .empty-frame {
		height: 230px;
		position: absolute;
		width: 39px;
		background: rgba(0, 0, 0, 0.6);
		top: 13%;
		opacity: 0.8;
	}
	.new-signup .steps.final-step.efl-main.clearfix {
		padding: 22px 45px;
		padding-bottom: 10px;
	}
}

@media all and (max-width: 1700px) {
	.new-signup .step1 {
		margin-top: -75px;
	}

	.after-reg-steps {
		margin-top: -78px;
	}
	.login-bg.bg2 {
		overflow: auto;
		overflow-x: hidden;
	}
	.new-signup .main-frame {
		margin-top: 96px;
	}
	.new-signup .qa-box {
		height: 240px;
		overflow: auto;
	}
	.knowledge-base-setup h4 {
		margin-top: 0px;
	}
	.new-signup .empty-frame {
		height: 230px;
		position: absolute;
		width: 39px;
		background: rgba(0, 0, 0, 0.6);
		top: 13%;
		opacity: 0.8;
	}
	.new-signup .steps.final-step.efl-main.clearfix {
		padding: 22px 45px;
		padding-bottom: 10px;
	}
}

.checkmark__circle {
	stroke-dasharray: 25;
	stroke-dashoffset: 25;
	stroke-width: 1;
	stroke-miterlimit: 10;
	stroke: #7ac142;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: block;
	stroke-width: 3;
	stroke: #000;
	stroke-miterlimit: 10;
	margin: 10% auto;
	box-shadow: inset 0px 0px 0px #7ac142;
	animation: checkfill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes scale {
	0%,
	100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}
@keyframes checkfill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #7ac142;
	}
}

.crossmark__circle {
	stroke-dasharray: 25;
	stroke-dashoffset: 25;
	stroke-width: 1;
	stroke-miterlimit: 10;
	stroke: #d06079;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.crossmark {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: block;
	stroke-width: 3;
	stroke: #000;
	stroke-miterlimit: 10;
	margin: 10% auto;
	box-shadow: inset 0px 0px 0px #d06079;
	animation: crosfill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.crossmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes scale {
	0%,
	100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}
@keyframes crosfill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #d06079;
	}
}
.showTooltip {
	background-color: #000;
	padding: 2px 4px;
	font-size: 11px;
	border-radius: 5%;
	color: red;
	position: absolute;
	top: 0%;
}
.setpos {
	position: relative !important;
}

.new-signup li.ps-check {
	color: #fff;
}

.new-signup li.ps-check span.min-check {
	display: inline-block !important;
}
.not-matched span.min-check.wrong-ps {
	display: inline-block;
}

.not-matched span.min-check.wrong-ps {
	display: inline-block;
}

.new-signup li.ps-check span.min-check.wrong-ps {
	display: none !important;
}
.new-signup span.min-check {
	display: inline-block;
	width: 14px;
	margin-left: 5px;
	display: none;
}

.new-signup li.ps-check span.min-check {
	display: inline-block !important;
}

.change-password li.ps-check {
	color: #0088b8;
}

.change-password li.ps-check span.min-check {
	display: inline-block !important;
}
.not-matched span.min-check.wrong-ps {
	display: inline-block;
}

.not-matched span.min-check.wrong-ps {
	display: inline-block;
}

.change-password li.ps-check span.min-check.wrong-ps {
	display: none !important;
}
.change-password span.min-check {
	display: inline-block;
	width: 14px;
	margin-left: 5px;
	display: none;
}

.change-password li.ps-check span.min-check {
	display: inline-block !important;
}

.crossmark {
	margin: 0%;
	display: inline;
}

.locale-select-container {
	background-color: transparent;
	padding: 3px 10px;
	font-size: 14px;
	margin-top: -20px;
}

.locale-select-container .locale-select-con-value {
	display: inline-block;
	text-transform: uppercase;
	margin-right: 3px;
}

.locale-select-container .locale-select-con {
	display: inline-block;
	height: 28px;
	font-size: 14px;
	border-color: #e0e0e0;
	text-transform: uppercase;
	font-family: 'FontAwesome', 'sans-serif';
	width: 45px;
}

/* .locale-select-container .locale-select-con > option:first-child{
  font-family: 'FontAwesome', 'sans-serif';
} */

.profile-form-custom input {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #bdbdbd;
	border-radius: 0;
	outline: 0;
	height: 2.1rem;
	width: 100%;
	font-size: 1rem;
	box-shadow: none;
	box-sizing: content-box;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.profile-form-custom label {
	color: rgba(0, 0, 0, 0.54);
	padding: 0;
	font-size: 1rem;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	line-height: 1;
}

.upload-custom-up {
	height: 100% !important;
	padding: 0px !important;
	margin: 0px !important;
	left: 0px;
	cursor: pointer;
}
