table.borderless.table span.col-ico { position: absolute !important;}
table.report .filters { display: flex;justify-content: left;}
table.report.borderless.table span.cal-ico { position: absolute !important;}
.common-heading input.custom-search { width: 84% !important; padding-right: 44px !important; padding-left: 13px !important;}
.smallTalks .steam-label-toggle { margin-left: 0 !important; position: relative !important; width: 100% !important;}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .category-multiselect-custom-div p {
        padding-left: 18px;
    }
    }
    .smallTalks .task-stream-full {
        width: 100% !important;
    }
    .smallTalks .task-stream-full span.lable {
        margin-left: auto;
        display: inline-block !important;
    }
    .smallTalks .task-stream-full .edit-icon {
        right: 8px !important;
        left: auto;
        top: 10px;
    }
    .smallTalks .sm-task-stream-label .msgLblArea{
        width: 70%;
    }
    .smallTalks .sm-task-stream-label .msgLblArea .question{
        padding: 5px;
    }
    .smallTalks .task-stream-full .edit-icon  img {
        width: 25px;
    }
    input.form-control, textarea.form-control {
        border: 1px solid #f5f5f5!important;
     
    }
    .qf-wrap-left {
        width: 85%!important;
    }
    input.form-control {
         width: 96%;
      }
    .qf-wrap-right {
        margin-left: 85%!important;
        padding-top: 9px!important;
        margin-bottom: 30px;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .manage-qa.small-talks-expand table.table [type=checkbox]:checked, .manage-qa.small-talks-expand table.table [type=checkbox]:not(:checked) { position: absolute !important;left: 12px !important;top: 15px;}
	.sidebar {z-index: 9 !important;}
	.app-body {height: calc(100vh - 10vh ) !important;}
	.report .rdtPicker {width: 309px !important; border: thin solid #eaeaea;}
	.manage-qa.small-talks-expand table.table.report [type=checkbox]:checked, .manage-qa.small-talks-expand table.table.report [type=checkbox]:not(:checked) {top: 2px !important;}

  }

.category-save-filter { border-top: 1px solid #e2e2e2;}
.category-multiselect.filter-category .show.dropdown-menu button.btn.btn-secondary:hover {background: transparent !important;}
.modal.show .modal-dialog {-webkit-transform: none;transform: none;margin-top: 95px !important;}
.reports-top-20 {margin-top: 24px !important;}
.fiter-section .show-section .dropdown-toggle:after {
    top: 19px!important;
    color: #787878!important;
}
.steam-label-toggle.Action {
    padding-left: 9px;
}
.add-faq  .qf-wrap .qf-wrap-left input.form-control {width: 100%;border:1px solid #f5f5f5!important;}
.add-faq  .qf-wrap .qf-wrap-right { padding-top: 10px!important;}
.Classification-Hierarchy ul.nav.nav-tabs {
    min-width: auto;
    float: left;
    border: 0px !important;
}
.manage-qa.small-talks-expand {
    padding-bottom: 60px !important;
}
.convo-wrap .convo-wrapper {
    width: 388px !important;
}
.ov-content {
    top: 124px!important;
}
.css-ik6y5r {
    height: 156px!important;
    overflow: hidden !important;
    position: relative !important;
}
.css-11unzgr {
    height: 154px;
    overflow: scroll !important;
    overflow-x: hidden !important;
    position: absolute !important;
    top: 2px !important;

} 
.main-section.nofications {
    padding-bottom: 50px;
}
@media all and (max-width:1400px)
{
.common-heading .search-frame i {
    right: 24px;
}
}
.common-heading .search-frame i {right: 45px;}
@media all and (max-width:1366px)
{
.chat-list .Action, .chat-list .chat-date, .chat-list .chat-du, .chat-list .performance, .chat-list .rating, .chat-list .User-name {
    min-width: 13% !important;
}

}
.convo-wrapper p {
    word-break: break-all !important;
}
.train-mode .search-frame i {
    top: 2px;
    background: #fff !important;

} 
.train-mode .switch-view {
    margin-bottom: 28px !important;
}
.css-11unzgr {
    width: 100% !important;
}
.qna-label-rdo { margin-left: 10px !important; margin-top: 5px !important; left: unset !important;}

.qna-label-rdo-parent [type="radio"]:checked:after {  position: absolute;  content: "Label";  height: 30px; width: 30px; font-size: 14px; left: 18px; top: -3px; color: #3f51b5;}

.qna-label-rdo-parent [type="radio"]:not(:checked):hover:after {  position: absolute;  content: "Make Label";  height: 30px; width: 142px; font-size: 12px; left: -3px; top: 18px; color: #3f51b5; cursor: pointer;transition:all .2s;}
.smallTalks .sm-uttrence {
    height: 192px;
    overflow: auto;
}
.smallTalks .answer {  border-top: 1px solid #e2e2e2; padding-top: 3px; margin-top: 9px;}
.answer-content-area { font-size: 13px; color: #989898 !important;  font-style: italic; margin-bottom: 0;}
.accord-wrap.sm-talks {  margin-bottom: 20px !important; }
.smallTalks .gtk span.answered {text-align: left !important;border-right: 0 !important;padding-left: 0 !important;width: 100%;}
.smallTalks .sm-talk-edit {bottom: 34px !important;}      
body.sidebar-fixed .sidebar .nav .nav-item.nav-dropdown.open > ul.nav.nav-list-items {
    max-height: 1500px !important;
}  
.manage-qa.small-talks-expand table.table [type=checkbox]:checked, .manage-qa.small-talks-expand table.table [type=checkbox]:not(:checked) {
    position: static!important;
}
.chat-list .Action, .chat-list .chat-date, .chat-list .chat-du, .chat-list .performance, .chat-list .rating, .chat-list .User-name {
    min-width: 14%!important;
    max-width: 14%;
}
.smallTalks .task-stream-full .edit-icon {right: 38px!important;}
.smallTalks .sm-talk-edit {right: 32px;}
.modal-dialog.delete-card .custom-from .form-control {padding: 5px 10px;}
.main-list-chat .chat-list .Action, .main-list-chat .chat-list .chat-date, .main-list-chat .chat-list .chat-du, .main-list-chat .chat-list .performance, .main-list-chat .chat-list .rating, .main-list-chat .chat-list .User-name {
    min-width: 13%!important;
    max-width: 13%!important;
}
.main-list-chat .chat-list .Action {
    max-width: 18% !important;
}
.main-list-chat .chat-list li div {
    display: inline-block !important;
    vertical-align: top !important;
    max-width:15% !important;
}

.chat-list span.attr-wrapper-span {
    max-width: 100% !important;
    word-break: break-all;
}

.show-section .dropdown-toggle:after {
    top: 19px;
    color: #000 !important;
}
@media all and (max-width:1600px)
{
.manage-qa .txt-wrap.btn {
    margin-left: 11px!important;
    margin-bottom: 8px;
}
.pagination-control.clearfix {
    margin: 32px 0;
    padding-top: 138px;
}
}
