.filters { 
    display: flex;
    justify-content: flex-end;
}

.filters .custom-margin-form-group:nth-child(2){
    margin-left: 20px;
}

.filters .custom-margin-form-group label{
    font-size: 16px;
    margin-left: 10px;
}

label, 
.filters .custom-calendar{
    display: block;
}

.filters .custom-margin-form-group .custom-calendar .set-date-sec{
    background: #fff !important;
    color: #444;
    border: solid 1px #444;
}

.filters .custom-margin-form-group .custom-calendar .cal-right-zero{
    right: 0;
}

.filters .custom-margin-form-group .custom-calendar .cal-left-zero{
    right: unset;
    left: 0;
}

.show-section {
    display: inline-block;
    margin-right: 18px;
}

.custom-calendar-inner.cal-right-zero {width: 279px !important;}
.rdtPicker thead tr:first-child th:hover {background: transparent;color: #000;}
th.rdtNext:hover {cursor: pointer;}
th.rdtPrev:hover {cursor: pointer;}
.custom-calendar { float: right;}
.filters .custom-margin-form-group label {font-size: 16px;display: inline-block !important;margin-left: 2px;min-width: 45px;}
.custom-calendar {display: inline-block;position: relative;margin-left: 5px;}



/* reactjs calendar CSS  */


.report .rdtPicker {
    width:auto;
    border:thin solid #eaeaea
  }


