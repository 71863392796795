@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800&display=swap');


.chat-container{
    font-family: 'Open Sans' !important ;
    -webkit-font-smoothing: auto !important;

}
.content{
    font-family: 'Open Sans' !important ;
}
.chat-container .css-1b7yvbl,.chat-container [data-css-1b7yvbl]{   font-family: 'Open Sans' !important ;}
:focus {
    outline: none !important;
}

.passwordInput {
    background-color: Red;
    border-radius: 3px;
    color: White;
    display: flex;
    font-family: Calibri, 'Helvetica Neue', Arial, sans-serif;
    margin: 10px;
    padding: 5px;
}

.passwordInput .passwordInput__box {
    display: flex;
    flex: 1;
}

.passwordInput .passwordInput__label {
    padding: 10px;
}

.passwordInput .passwordInput__input {
    border: 0px;
    border-radius: 3px;
    flex: 1;
    letter-spacing: .5em;
    outline: 0;
    padding: 0 10px;
    width: 100%;
}

.passwordInput .passwordInput__input:disabled {
    background-color: rgba(255, 255, 255, .5);
    color: White;
}

.chat-container {
  
    /* height: 560px !important;
    min-height: 464px !important; */
    background: #fff !important;
    -webkit-box-shadow: 2px 9px 16px 4px rgba(0, 121, 251, 0.08);
    -moz-box-shadow: 2px 9px 16px 4px rgba(0, 121, 251, 0.08);
    box-shadow: 2px 9px 16px 4px rgba(0, 121, 251, 0.08);
    position: fixed;
    right: 17px;
    border-radius: 8px;
    overflow: hidden;
    bottom: 109px;
    transition: all .4s;
    z-index: 9;
    position: absolute;
    top: 0;
    height: 519px;
    width: 94%;
    margin-bottom: 100px;
    
}
.chat-container  img,.chat-container  svg {
    vertical-align: initial;
}

.chat-container.maximize-bot div[role=complementary]  {
    width: 100% !important;
    height: 565px !important;
    transition: all .4s;
}
/* .chat-container>.chat-header {
    background-color: #162D7C;
    position: relative;
} */

.chat-container>.chat-header {
    background: #1e90ff; /* Old browsers */
background: -moz-linear-gradient(left, #1e90ff 0%, #2b90f1 42%, #0a67c2 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, #1e90ff 0%,#2b90f1 42%,#0a67c2 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, #1e90ff 0%,#2b90f1 42%,#0a67c2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e90ff', endColorstr='#0a67c2',GradientType=1 ); /* IE6-9 */
    position: relative;
    height: 52px;
    box-shadow: 0px 0px 12px 5px rgba(0, 121, 251, 0.08);
}
/* span.notify.disconnect {
    display: none;
} */

body {
    background: #f0f6ff;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

span.restart-icon img {
    width: 18px;
    position: relative;
    top: 1px;
}
.webchat__bubble__content ul li {
    margin-bottom: 1px;
}
span.bot-name {
    font-size: 16px;
    color: #ffffff;
}
.content {
    margin-bottom: 16px;
}
.chat-header {
    padding: 0px 11px;
    padding-top: 15px;
}

span.header-icons {
    padding-top: 0px;
}

.ac-container.ac-adaptiveCard {
    background-color: transparent !important;
    box-shadow: none !important;
    /* padding: 3px !important; */
    border: 0px !important;
}

.ac-actionSet .ac-pushButton {
    margin-right: 5px;
    margin-bottom: 12px;
    border-radius: 50px;
    font-weight: 300 !important;
}

.ac-actionSet {
    flex-flow: wrap !important;
}

.ac-pushButton {
    appearance: none;
    background-color:transparent !important;
    border-style: solid;
    border-width: 1px;
    color: #1e90ff !important;
    font-weight: 500;
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-color: #1e90ff !important;
    font-size: 14px;
}

.ac-pushButton:focus {
    outline: none !important;
}

.chat-container div[role=complementary] {
    background-color: #f0f6ff !important;
    /* height: calc(100% - 52px) !important; */
    width: 100% !important;
    height: 500px !important;
    transition: all .5s;
}

.css-eycyw2.css-1882x3w.css-1l9a57c {
    background: #fff;
    box-shadow: 0px 0px 12px 5px rgba(0, 121, 251, 0.08);
}

.css-eycyw2>.main {
    align-items: stretch;
    background-color: White;
    min-height: 52px !important;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    box-shadow: 0px 1px 14px 1px rgba(0, 121, 251, 0.08) !important;
    border: 0px !important;
    padding-top:0px !important;
    padding-left: 0px !important;
}

form.css-16qahhi.css-n2zczk.css-1mou18w.css-1fe8kfc input {
    border-bottom: 1px solid #c9cdd1;
    color: #6f7d8c!important;
}

.css-115fwte svg {
    fill: #839bb3;
    font-size: 25px !important;
    width: 33px;
    margin-top: 4px;
}

.css-n2zczk,
[data-css-n2zczk] {
    padding-right: 2px !important;
}

.webchat__bubble__content {
    background: transparent !important;
    border: 0px !important;
    color: #6f7d8c!important;
    font-size: 14px;
}
/* .ac-container p {
    color: #6f7d8c;
    font-weight: 400;
} */
/* .chat-container .ac-container:first-child > div:first-child>p:first-child{
    font-weight: 400 !important;
} */
.from-user .bubble .ac-container p {
    color: #6f7d8c!important;
}
.bubble {
    margin-left: 19px;
}

/* .bubble :after {
    position: absolute;
    top: 10px;
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
} */

.from-user .bubble {
    background: #e5eefc;
    border-radius: 5px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
    margin-right: 12px;
}


.timestamp.transcript-timestamp {
    margin-left: 17px;
}

.from-user .timestamp.transcript-timestamp {
    margin-right: 14px;
}

span.chat-options img {
    width: 6px;
   
}
span.restart-icon {
    margin-right: 12px !important;
    position: relative;
    top: -1px;
}
.svg-inline--fa.fa-w-14 {
    width: 0.875em;
    font-size: 17px;
    vertical-align: 1px;}

.exp-menu-frame {
    position: absolute;
    right: 0;
    background: #fff;
    width: 100%;
    left: 0;
    min-height: 300px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 2;
    padding:0 15px;
    height: 303px;
    overflow: scroll;   
}
.menuItem {
    padding: 18px 0;
    padding-bottom:0px; 
    
}
.menuItem svg {
    margin-right: 10px !important;
    color: #839bb3;
}
.menuItem img {
    width: 19px;
    margin-right: 9px;
}




    


*, *::before, *::after {
    box-sizing: border-box;
}


.css-gtdio3.css-mfy564{    margin-bottom: 85px;}
.css-eycyw2 > .main, [data-css-eycyw2] > .main {
    bottom: 0;
    position: absolute;
    width: 100%;
}
.click-begin {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
}
.click-begin span:hover{cursor: pointer;}
.click-begin span {
    background: #1e90ff;
    display: inline-block;
    width: 100%;
    padding: 17px 0;
    font-size: 14px;
  
}
.ac-container p {
    /* color: #6f7d8c; */
    /* font-size: 14px;
    font-weight: 400; */
    font-family: 'Open Sans',!important;
}
.webchat__bubble__content p{font-family: 'Open Sans',!important;margin-bottom: 5px;}
.ac-actionSet .ac-pushButton{font-family: 'Open Sans', sans-serif !important;}
input.ac-input, select.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact {
    height: 35px;
    border-radius: 5px;
    border: 0px;
    color: #6f7d8c;
    font-size:14px;
    padding:0px 10px;
    background-color: #fff;
}
.ac-input.ac-choiceSetInput-expanded {
    padding: 1px;
    border: 0px !important;
}
textarea.ac-input.ac-textInput.ac-multiline {
    height: 78px;
    font-size: 14px;
    padding: 5px 10px;
    border: 0px;
    border-radius: 5px;
    color: #6f7d8c;
}
.menuItem span{color: #6f7d8c;font-size: 12px;}

.scaleup {
    position: absolute !important;
    width: 0px !important;
    right: -100% !important;
    display: block;
    left: auto !important;
    transition: all .4s;
    
}

.transform-in{   
    width: 250px !important;
    transition: all .4s;
    right: 0 !important;
}
.notify {
    position: relative;
    top: 2px;
    right: -10px;
}
    .notify .beats {
      position: absolute;
      top: -14px;
      right: 3px;
      height: 12px;
      width: 12px;
      z-index: 10;
      border: 7px solid #16ca03;
      border-radius: 55px;
      -moz-animation: beats 1s ease-out;
      -moz-animation-iteration-count: infinite;
      -o-animation: beats 1s ease-out;
      -o-animation-iteration-count: infinite;
      -webkit-animation: beats 1s ease-out;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      }
    .notify .point {
      width: 6px;
      height: 6px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      background-color: #16ca03;
      position: absolute;
      top: -10px;
    right: 7px;}
    .notify.disconnect .point {
        width: 6px;
        height: 6px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: red;
        position: absolute;
        top: -5px;
        right: 8px;}
   
    span.restart-icon.live-chat {
        position: relative;margin-right: 8px;
    }

      @-moz-keyframes beats {
        0% {
          -moz-transform: scale(0);
          opacity: 0.0; }
        25% {
          -moz-transform: scale(0.1);
          opacity: 0.1; }
        50% {
          -moz-transform: scale(0.5);
          opacity: 0.3; }
        75% {
          -moz-transform: scale(0.8);
          opacity: 0.5; }
        100% {
          -moz-transform: scale(1);
          opacity: 0.0; } }
      
      @-webkit-keyframes beats {
        0% {
          -webkit-transform: scale(0);
          opacity: 0.0; }
        25% {
          -webkit-transform: scale(0.1);
          opacity: 0.1; }
        50% {
          -webkit-transform: scale(0.5);
          opacity: 0.3; }
        75% {
          -webkit-transform: scale(0.8);
          opacity: 0.5; }
        100% {
          -webkit-transform: scale(1);
          opacity: 0.0; } }

    .css-1tdb3h1.image {
    height: 40px;
    width: 35px !important;
}
svg.svg-inline--fa.fa-expand-alt.fa-w-14 {
    font-size: 16px;
    position: relative;
    top: -1px;
    vertical-align: -2px;
}
.ac-pushButton:hover {
    background: #1e90ff!important;
    color: #fff !important;
    border-color: #1e90ff !important;
    transition: all .4s;
    cursor: pointer;
}
span.header-icons span:hover {
    cursor: pointer;
}
.ac-pushButton.style-default {
    min-width: 90px;
}
.locale-select-con-value{
    /* color: #fff; */
    padding: 4px;
}

.close-bot{
    bottom: -500% !important;
}
.chat-container select.locale-select-con {
 
    height: 21px;
    border: 0px !important;
    position: relative;
    top: -2px;
    padding: 0 4px;
    font-weight: 600;
}
.chat-container select{font-size: 10px !important;}
.chat-container .click-begin.gray-out span{
    background-color: #f6f5f9 !important;
}
span.itemName:hover {
    cursor: pointer;
}
.chat-container  .bubble {
    background: #fff ;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.08);
    border-radius: 5px;padding: 20px;
}
.chat-container  .ac-input {
    font-size: 14px !important;
    padding: 0px 13px !important;
    min-height: 40px !important;
    border-radius: 5px !important;

}
.chat-container select.ac-input {
    font-size: 14px !important;
}
.chat-container .ac-actionSet .ac-pushButton {
    padding: 6px 12px;
}
.chat-container .markdown {
    box-shadow: none;
    padding: 0;
}
.chat-container .webchat__bubble__content {
    min-height: auto !important;
}
.chat-container .webchat__bubble__content p {
    padding: 0;
}
.chat-container  .ac-container {
    margin-top: 2px !important;
}
.chat-container  .ac-horizontal-separator {
    margin-top: 6px;

}
.exp-menu-frame .menuItem {
    text-align: left;
}
.exp-menu-frame {
    min-height: 95px;
    height: auto !important;
    overflow: auto;
    box-shadow: 0px 1px 14px 1px rgba(0,121,251,0.08) !important;
}
.chat-container .bubble {
    box-shadow: 0px 1px 14px 1px rgba(0,121,251,0.08) !important;
    padding: 10px 18px;
    margin: 1px 15px 3px;
}
.chat-container .webchat__bubble__content p {
    padding: 0 !important;
    margin-bottom: 0px !important;
    min-height: auto !important;
}

form.css-16qahhi.css-n2zczk.css-1mou18w.css-1fe8kfc input {
    font-family: 'Open Sans' !important;
    padding-bottom: 8px;
}
.chat-container .main form input {  font-family: 'Open Sans' !important;
    padding-bottom: 8px;} 
          
/* .chat-container .webchat__row.attachment {
        display: inline-block !important;
} */
.chat-container .from-bot-botname{
    margin-top: -16px;
    margin-left: 60px;
    font-size: 11px;
    color: #999;
    display: block;
}
.chat-container .from-user-botname{
    margin-top: -16px;
    margin-right: 24px;
    font-size: 11px;
    color: #999;
    float: right;
}
.chat-container .ac-actionSet .ac-pushButton {
    padding: 6px 8px;
    font-size: 13px;
}
.ac-container .ac-textBlock {
    display: block !important;
    /* flex: auto !important; */
    flex-wrap: wrap !important;
}   
.chat-container .ac-container .ac-textBlock {
    display: block !important;
    /* flex: auto !important; */
    flex-wrap: wrap !important;
    max-height: 100% !important;
}
/* .chat-container .ac-container:first-child > div:first-child > p:first-child {
    color: #6f7d8c;
    font-size: 16px;
}    */
.chat-container .ac-container {
    margin-top: 2px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.chat-container button.css-1m1alj7 {
    position: sticky;
    float: right;
    background-color: #3b96f2;  
} 
.chat-container .menuItem {
    padding: 9px 15px;
    padding-bottom: 8px;
}
.chat-container .menuItem:last-child {
    padding-bottom: 15px;
}
.chat-container .exp-menu-frame {
    padding: 0 0 !important;
    min-height: auto !important;
}
.chat-container .content ul li .webchat__bubble__content {
    background: #fff !important;
    border-radius: 5px;
    position: relative;
    left: 13px;
    padding: 10px 16px !important;
    width: 385px;
}


/* .chat-container table.ac-factset tr td:first-child {
    width: 152px;
    padding-bottom: 5px !important;
} */
/* .chat-container table.ac-factset tr td:first-child p {
    font-weight: 600 !important;
    font-size: 14px;
}         */
.chat-container .ac-input-container {
    width: 100% !important;
}
.chat-container input.ac-input.ac-dateInput {
    min-height: auto !important;
    padding: 10px 3px !important;
}
.chat-container .css-1eu9xwl.avatar{
    background-color: transparent !important;
}
.chat-container [type=radio]:checked, .chat-container [type=radio]:not(:checked) {
    position: relative !important;
    left: 0 !important;
    top: -2px !important;
}
.chat-container .ac-input [type=radio]:checked, .chat-container [type=radio]:not(:checked) {
    position: relative !important;
    left: 0px!important;
    top: -2px!important;
}
.chat-container [type=checkbox]:checked, .chat-container [type=checkbox]:not(:checked) {
    position: relative !important;
    left: 0 !important;
    top: -3px !important;
}
.chat-container .ac-input.ac-choiceSetInput-multiSelect {
    border: 0px !important;
}
.chat-container.ac-input.ac-toggleInput {
    border: 0px !important;
}
.chat-container .ac-input.ac-toggleInput {
    min-height: auto !important;
    border: none !important;
}
.chat-container .ac-container:first-child>div:first-child>p:first-child {
    font-weight: unset !important;
}

.chat-container .from-bot-botname {
    margin-left: 28px;
    font-size: 11px;
    color: #999;
}
.css-1qyo5rb, [data-css-1qyo5rb] {
    margin-bottom: 18px !important;
}
.chat-user span.sender-name {
    float: right !important;
    text-align: right !important;
}

/* new message button css */
.chat-container button.css-1m1alj7 {
    font-size: 0px;
    height: 35px;
    width: 35px;
    border-radius: 100px;
    opacity: .5;
    /* position: relative !important; */
}
.chat-container button.css-1m1alj7:after {
    position: absolute;
    content: "\2039";
    width: 40px;
    background: transparent;
    z-index: 9;
    color: #fff;
    font-size: 34px;
    top: -7px;
    right: 1px;
    transform: rotate(270deg);
}
.chat-container button.css-1m1alj7:hover {
    opacity: 1;
    transition: auto;
}

.chat-user .from-user.webchat__bubble_has_nub.bubble
{
    background-color:#7986cb;

}  
.chat-user .from-user.webchat__bubble_has_nub.bubble p
{
    color: #fff;

}       
.from-user-message .webchat__bubble_has_nub.bubble {
    background: #7986cb;
    color: #fff;
}
.from-user-message .webchat__bubble_has_nub.bubble p {
    color: #fff;
}
.from-agent .webchat__bubble_has_nub.bubble {
    background: #7986cb;
    color: #fff;
}
.from-user-message .webchat__bubble_has_nub.bubble p {
    color: #fff;
}

.chat-user .from-agent-message .from-user.webchat__bubble_has_nub.bubble {
    background-color: rgb(26, 188, 156);
}


              
              