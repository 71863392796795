/* // agent chat css */

.availability {
	text-align: right;
	font-size: 18px;
	color: #989898;
	margin-top: 5px;
}

.switch.switch-text .switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
	background-color: #fff;
	border: 1px solid #c2cfd6;
	border-radius: 2px;
	transition: opacity background 0.15s ease-out;
	border-radius: 50px;
	width: 62px;
}

.switch.switch-text .switch-input:checked ~ .switch-handle {
	left: 39px;
}

.switch.switch-text .switch-handle {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 20px;
	height: 20px;
	background: #fff;
	border: 1px solid #c2cfd6;
	border-radius: 1px;
	transition: left 0.15s ease-out;
	border-radius: 100px;
}

span.tog-switch {
	padding-left: 15px;
}

span.que-icon .fa {
	vertical-align: middle;
}

ul.pe-list {
	padding-left: 0;
	list-style: none;
}

.agent-chat-list span.badge-count {
	min-width: 15px;
	color: #fff;
	height: 15px;
	margin-top: 3px;
}

.pe-list a {
	font-size: 16px;
}

.waiting-h {
	background: #e73935 !important;
}

.waiting-m {
	background: #f1c40f !important;
}

.waiting-l {
	background: #78d227 !important;
}

ul.accord-row {
	padding-left: 0;
	list-style: none;
	padding: 0 0;
}

.Collapsible {
	padding: 0px 0px;
	border-bottom: 1px solid #dbdbdb;
	font-size: 16px;
}

.Collapsible:hover {
	cursor: pointer;
}

span.Collapsible__trigger {
	padding: 14px 16px;
	display: block;
}

.Collapsible ul.pe-list {
	padding-left: 0;
	list-style: none;
	background: #fff;
}

span.new-chat {
	float: right;
	vertical-align: middle;
}

.agent-chat-list img {
	margin-right: 10px;
	vertical-align: middle;
}

.accord-wrap .pe-list li:hover a span {
	border: 0px;
}

.chat-head h4 {
	font-size: 18px;
	font-weight: 500;
	color: #fff;
	background: #f45d59;
	margin-bottom: 0px;
	padding: 16px 17px;
}

.chat-window.card {
	overflow: hidden;
}

.chat-window .user-min-info {
	background: #f7f7f7;
	padding: 6px 16px;
	border-radius: 5px;
	color: #989898;
	font-weight: 500;
}

.chat-window .user-min-info p {
	margin: 15px 0;
}

input.chat-input {
	height: 45px;
	border: 1px solid #e2e2e2;
	border-radius: 50px;
}

.chat-window.card .input-wrap {
	padding: 17px 32px;
	position: relative;
}

.chat-window input.chat-input {
	height: 45px;
	border: 1px solid #e2e2e2;
	border-radius: 50px;
	padding: 0 16px;
	width: 97%;
	margin: 0px auto;
}

.chat-window .chat-bottom {
	background: #f7f7f7;
}

.chat-window .chat-frame {
	position: absolute;
	bottom: 0;
	padding-bottom: 15px;
	width: 100%;
}

.chat-window .User-msg p {
	min-width: 50px;
	background: #ececec;
	padding: 14px 9px;
	margin-bottom: 20px;
	border-radius: 50px;
	border-top-left-radius: 0px;
	float: left;
}

.chat-window .agent-msg p {
	min-width: 50px;
	background: #f9aeac;
	padding: 14px 9px;
	margin-bottom: 20px;
	border-radius: 50px;
	border-bottom-right-radius: 0px;
	float: right;
	color: #fff;
}

.chat-window .agent-msg {
	padding-right: 40px;
}

.chat-window p.chat-dates {
	text-align: center;
	margin-bottom: 40px;
}

.chat-window.card {
	overflow: hidden;
	width: 100%;
	float: right;
}

.user-info.card {
	margin-left: 69%;
	padding-left: 0px;
	text-align: center;
}

.uic-top {
	background: #142e50;
	height: 130px;
}

.uic-top h4 {
	color: #fff;
	font-size: 16px;
	padding: 8px 0;
}

img.big-avt {
	margin-top: 23px;
}

.uic-body {
	padding: 65px 0 12px;
}

.uic-body {
	padding: 65px 0 12px;
	color: #989898;
	font-size: 16px;
}

.uic-bottom p {
	margin-bottom: 0;
	color: #989898;
	font-size: 16px;
}

span.id-ico {
	float: left;
	margin-left: 15px;
}

.chat-window.card .chat-body {
	padding: 18px 19px;
	background: #fff;
	height: 440px;
	position: relative;
	overflow: auto;
	overflow-x: hidden;
	margin-bottom: 16px;
}

.agent-chat-list .pe-list li:hover a span {
	border: 0px;
	color: #fff;
}

.uic-bottom {
	background: #f7f7f7;
	padding: 10px 0;
}

/* agent chat renew */

.agent-chat-list img {
	width: auto;
}

.agent-chat-list h4.view-head {
	background: #7986cb;
	color: #fff;
	font-size: 17px;
	font-weight: 500;
	padding: 14px 16px;
}

.uic-top img {
	width: auto;
}

.chat-window.card .input-wrap {
	padding: 23px 0px;
	position: relative;
}

.chat-window input.chat-input {
	padding: 14px 16px;
	width: 93%;
	margin: 0px auto;
}

.chat-window span.chat-begin {
	position: absolute;
	background: #3fc380;
	width: 100%;
	top: 24px;
	padding: 11px 8px;
	border-radius: 50px;
	left: 0px;
	right: auto;
	text-align: center;
	color: #fff;
	font-size: 16px;
	height: 49px;
}

.uic-top {
	background: #7986cb;
}

span.tog-switch h4 {
	font-size: 13px;
}

span.tog-switch .btn {
	background: transparent;
	color: #686868;
	border: 0px;
}

.availability .title {
	position: relative;
	top: -7px;
	right: 10px;
}

/** agent chat **/

.chat-window.card .bot-frame {
	width: 100% !important;
	bottom: 54px;
}

.chat-window.card .userIcon {
	display: none;
}

.chat-window.card .asc-bot-v2 .wc-header {
	padding-left: 14px;
	padding-top: 14px;
	color: #fff;
	background-color: #7986cb !important;
}

button.discon-btn {
	background: transparent;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 100px;
	font-size: 13px;
	position: absolute;
	right: 12px;
	z-index: 99;
	top: 11px;
}

button.discon-btn:hover {
	background: #e74c3c;
	border-color: #e74c3c;
}

.profile-edit-option.views.card.agent-chat-list {
	margin-bottom: 65px;
	padding-bottom: 25px !important;
}

.chat-window.card .pe-list a {
	padding: 10px 15px;
}

.chat-window.card .pe-list a {
	font-size: 14px;
}

.chat-window.card .pe-list li:hover a {
	color: #787878 !important;
}

.chat-window.card .pe-list li:hover {
	background: #f0f6ff;
	color: #fff;
}

ul.pe-list.incomming-user {
	height: 214px;
	overflow: auto;
}

.agent-chat-list .pe-list li:hover a span {
	color: #787878 !important;
}

.agent-chat-list .collap-list {
	padding-left: 0;
	position: relative;
	margin-bottom: 18px;
}

.agent-chat-list .accord-wrap p {
	margin-bottom: 0px;
	font-size: 14px;
	text-transform: capitalize;
}

.agent-chat-list .Collapsible.collap-list {
	margin-bottom: 0px;
}

.agent-chat-list span.Collapsible__trigger {
	padding: 10px 15px;
	display: block;
}

.agent-chat-list h4.view-head {
	font-size: 14px;
}

ul.pe-list.incomming-user {
	min-height: 0px;
	height: auto;
	max-height: 214px;
	overflow: auto;
}

.agent-chat-list .pe-list a {
	padding: 10px 15px;
	font-size: 14px !important;
}

.agent-chat-list .Collapsible__contentInner .Collapsible a {
	padding-left: 19px;
}

.add-remove-tool .two-col-input.form-group {
	border: 1px solid #e2e2e2;
	border-radius: 5px;
	padding: 0;
	margin-top: 16px;
	overflow: hidden;
}

.custom-from.add-remove-tool select.form-control {
	background: transparent;
	border: 0px solid;
	border-bottom: 0px;
}

.tools.adar-tools button.btn.btn-outline-primary {
	background: #f5f5f5;
	border: 1px solid #e2e2e2;
	width: 100%;
	padding: 6px 0;
	font-size: 13px !important;
	margin-bottom: 8px;
}

.add-remove-tool .two-col-input.form-group label {
	background: #f5f5f5;
	display: block;
	padding: 8px 10px;
}

.ms-out {
	padding: 0px 0px;
}

.tools.adar-tools {
	padding-top: 40px;
}

.add-remove-tool .custom-from option {
	color: #787878;
	padding: 12px 0px !important;
}

.custom-from option {
	color: #787878;
	/* height: 32px; */
	line-height: 9px !important;
	padding: 10px 11px !important;
}

.asc-accord ul.accord-row a {
	color: #000;
	margin-bottom: 8px !important;
	display: inline-block;
	font-size: 15px;
}

.asc-accord ul.accord-row a:hover {
	color: #f45d59 !important;
	text-decoration: none;
}

.ag-chat .ad-faq {
	text-transform: uppercase;
	font-size: 16px;
}

.modal-dialog.delete-card.editor-frame.add-faq.ag-modal-width {
	width: 54%;
}

.alert-agent.blink {
	position: relative;
}

ul.pe-list.incomming-user li {
	position: relative;
}

li.pe-list.incomming-user {
	position: relative;
}

.hideAllBtn button.ac-pushButton {
	display: none !important;
}

/* // media query */

@media screen and (max-width: 1368px) {
	.agent-chat-list .accord-row li.active span.Collapsible__trigger {
		background: transparent;
		color: #f45d59;
		border-bottom: 0px;
	}
}

.ch-convo {
	width: 94%;
	height: 432px;
	overflow: auto;
	background: #f5f5f5;
	padding: 27px 15px;
	position: absolute;
	bottom: 0;
	border-radius: 8px;
}

.convo-wrap.from-bot {
	float: left;
}

.chat-window p.chat-dates {
	text-align: center;
	margin-bottom: 20px;
}

.convo-wrap.from-me.col-blue {
	float: right;
}

.convo-wrap.from-me.col-blue p {
	background-color: #e85a5b !important;
	color: #fff;
	border-radius: 10px !important;
	font-size: 13px !important;
	padding: 10px 12px;
}

.convo-wrap.from-bot.col-white p {
	background-color: #fff !important;
	color: #333 !important;
	border-radius: 10px !important;
	font-size: 13px !important;
	padding: 10px 12px;
}

@-webkit-keyframes blinker {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.blink {
	text-decoration: blink;
	-webkit-animation-name: blinker;
	-webkit-animation-duration: 0.8s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-direction: alternate;
}

.alert-agent a,
.alert-agent span {
	color: red !important;
}

.queue_conditions select {
	width: 25%;
	float: left;
	margin-left: 15px;
}

.queue_category input {
	width: 48%;
	float: left;
}

.queue_category select {
	width: 48%;
	float: left;
	margin-left: 10px;
	margin-top: 4px;
}

.queue_category {
	clear: both;
}

.queue_category_or {
	float: left;
}

.sidebar .nav-dropdown.open {
	background: transparent;
}

table.table .filters label {
	margin-bottom: 0;
	position: relative;
	text-align: left;
	display: inline-block;
	width: 100%;
	font-size: 13px;
}

.asc-accord-1 span.Collapsible__trigger:before {
	position: absolute;
	height: 12px;
	width: 12px;
	content: '';
	border-radius: 100%;
	left: auto;
	top: 0px;
	color: #142e50;
	background-size: 100% !important;
	right: 15px;
	top: 13px;
	color: #142e50;
	background: url(/assets/img/before-expand.png);
}

.asc-accord-1 span.Collapsible__trigger.is-open:before {
	background: url(/assets/img/after-expand.png) !important;
	background-size: 100% !important;
	height: 12px;
	width: 12px;
}

.asc-accord-1 li {
	position: relative;
}

.ar-position-1 .Collapsible {
	position: relative;
}

.ar-position-1 span.pull-right.badge.badge-success {
	margin-right: 22px;
	margin-top: 2px;
}

span.tog-switch .btn {
	background: #f45d59;
	color: #ffffff;
	border: 1px solid #d2d2d2;
}

span.tog-switch h4 {
	font-size: 13px;
}

span.tog-switch .btn {
	background: #fff;
	color: #989898;
	border: 1px solid #e2e2e2;
	border-radius: 100px;
	padding-top: 14px;
	cursor: pointer;
}

span.btn.toggle-on.btn-xs.btn-primary {
	background: #3fc380;
	color: #fff;
	border: 1px solid #3fc380;
}

.toggle.btn-xs {
	min-width: 26px;
	width: 78px !important;
	margin-top: -15px;
}

span.btn.toggle-off.btn-xs.btn-warning {
	background: #d39e00;
	color: #fff;
	border: 1px solid #d39e00;
	padding-left: 28px;
}

.toggle-handle.btn-xs {
	top: 2px !important;
	right: 13px;
}

.btn-xs.off .toggle-handle.btn-xs {
	left: 13px;
}

span.toggle-handle.btn.btn-xs.btn-default {
	display: inline-block;
	padding: initial;
	width: 18px;
	height: 18px;
}

.css-1pcexqc-container {
	color: #787878 !important;
}

.chat-window.card .bot-frame {
	width: 100% !important;
	bottom: 0;
}

.chat-window.card .wc-chatview-panel {
	overflow: hidden;
	position: static !important;
}

.chat-window.card .bot-frame.asc-bot-v2 .wc-chatview-panel {
	max-height: 101% !important;
	width: 100% !important;
}

.chat-window.card .asc-bot-v2 .wc-chatview-panel {
	right: 0;
	min-height: 450px !important;
	bottom: -529px !important;
}

.chat-window.card .asc-bot-v2 .wc-header span:after {
	display: none !important;
}

.chat-window .input-wrap span.chat-begin {
	width: 100% !important;
}

.chat-user span.user-name {
	float: left !important;
	text-align: left;
}

.chat-user .from-bot span.sender-name {
	float: right !important;
	text-align: left;
}

.agent-chat-list ul.pe-list {
	margin-bottom: 0px;
}

.chat-head h4 {
	font-size: 14px;
	padding: 14px 17px;
}

span.LinkFix {
	width: 172px;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

span.id-top {
	position: absolute;
	display: inline-block;
	width: 100%;
	left: 0;
	top: 5px;
	right: 0;
	padding-left: 8px;
	z-index: 9;
}

.agent-chat-list ul.pe-list li {
	height: 44px;
}

li.pe-list.incomming-user a:hover {
	cursor: not-allowed !important;
}

li.pe-list.incomming-user .LinkFix:hover {
	cursor: pointer !important;
}

.delete-card button.btn:hover {
	color: #333;
}

span.id-top.active-user-chat:after {
	background: green;
	position: absolute;
	content: '';
	right: 0;
	height: 43px;
	width: 4px;
	top: -4px;
}

.chat-window.card .asc-bot-v2 .wc-list {
	border-top: 0px !important;
	margin-top: 0px !important;
}

.chat-window.card .asc-bot-v2 .ac-container > div p {
	color: #fff !important;
}

/* .chat-window.card .ac-input {color: #fff !important;} */

/* .chat-window.card .asc-bot-v2 p {color: #fff !important;} */

.convo-wrap.from-me.col-blue .ac-textBlock {
	font-size: 13px !important;
	color: #fff !important;
	font-weight: 400 !important;
}

.convo-wrap.from-me.col-blue .ac-container.ac-adaptiveCard {
	background: #e85a5b !important;
	border-radius: 10px !important;
}

a.g-select.active-incoming-history:after {
	background: green;
	height: 40px;
	width: 3px;
	position: absolute;
	content: '';
	right: 0;
}
a.active {
	color: #f45d59 !important;
}

.asc-bot-v2.hide-cosole-input .wc-console {
	display: none;
}

.agent-chat-list.asc-accord-1 .badge-success {
	margin-right: 20px;
}

.agent-chat-list .pe-list a {
	text-decoration: none;
}

.hide-cosole-input .wc-chatview-panel {
	background: #f5f5f5 !important;
}

.hide-cosole-input.wc-message-groups {
	bottom: 3px;
}

.agent-chat-list .pe-list a {
	padding: 9px 7px;
	font-size: 12px !important;
}

span.time-stamp {
	text-align: right;
	display: block;
	float: right;
	font-style: italic;
	color: #898989;
	font-size: 9px;
	padding-top: 6px;
}

.agent-chat-list span.badge-count {
	min-width: 9px;
	color: #fff;
	height: 9px;
	margin-top: 4px;
	margin-left: 5px;
}

.LinkFix {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 14px 0px;
	padding-left: 50px;
	top: -1px;
}

/**** for calandar ******/

.custom-calendar .custom-calendar-inner table {
	width: 100% !important;
	border-collapse: collapse;
	padding: 0px 0px !important;
	position: relative;
	left: 0px;
	text-align: center !important;
	top: -24px;
}

.filters .custom-margin-form-group .custom-calendar .cal-left-zero {
	right: unset;
	left: 0;
	width: 279px;
}

.custom-calendar .custom-calendar-inner table thead tr:first-child th {
	padding: 39px 0;
	padding-bottom: 8px;
}

/**** for agent dashboard ******/

.css-1pcexqc-container div {
	background: #fff !important;
}

.convo-wrap [type='radio']:checked,
.convo-wrap [type='radio']:not(:checked) {
	position: static !important;
}

.dropdown.show button:hover {
	color: #000 !important;
}

.css-xo7z33-option {
	color: #000 !important;
}

.rating-user {
	width: 50% !important;
	text-align: left !important;
}

.ag-chat .ad-faq {
	text-transform: uppercase;
	font-size: 16px;
	left: -9px !important;
	top: -1px !important;
	margin-right: 10px;
}

.chat-user .ch-convo .convo-wrap.from-me.col-blue {
	float: right !important;
}

.sidebar-fixed.sidebar-lg-show .main .chat-container .main {
	margin-left: 0px !important;
}

.custom-accord .pe-list li:hover {
	background: #f1f1f1 !important;
	color: #fff !important;
}

.custom-accord .accord-row li.active span.Collapsible__trigger {
	background: #fff !important;
	color: #6f7d8c !important;
}

.custom-accord span.id-top {
	top: 8px;
}

span.Collapsible__trigger.is-open {
	border-bottom: 1px solid #e2e2e2;
}

.custom-accord .Collapsible {
	border-bottom: 0px !important;
}

.custom-accord span.id-top.active-user-chat:after {
	background: #2f9203;
	position: absolute;
	content: '';
	right: 7px;
	height: 10px;
	width: 10px;
	top: 8px;
	border-radius: 100%;
}

.chat-user .convo-wrap.from-bot {
	float: left !important;
}

.chat-container button.ac-pushButton {
	border: 1px solid #1e90ff !important;
	border-radius: 50px !important;
	color: #1e90ff !important;
}

.chat-container button.ac-pushButton:hover {
	border: 1px solid #1e90ff !important;
	border-radius: 50px !important;
	color: #fff !important;
	background-color: #1e90ff !important;
}

.agent-chat-list h4.view-head {
	background: #fff !important;
	color: #333;
	border-bottom: 1px solid #e2e2e2;
	position: relative;
	padding: 14px 0 !important;
}

.agent-chat-list h4.view-head:after {
	position: absolute;
	left: 0;
	content: '';
	background: #7986cb;
	height: 4px;
	width: 45px;
	bottom: -2px;
	z-index: 1;
}

.profile-edit-option.views.card.agent-chat-list {
	margin-bottom: 65px;
	padding: 0 14px;
}

.asc-accord-1 span.Collapsible__trigger:before {
	position: absolute;
	height: 10px !important;
	width: 10px !important;
	left: auto;
	top: 0px !important;
	color: #142e50;
	background-size: 100% !important;
	right: 0px !important;
	top: 15px !important;
	color: #142e50;
	background: url(/assets/img/before-expand.png);
}

.agent-chat-list span.Collapsible__trigger {
	padding: 10px 0 !important;
	display: block;
	padding-left: 6px;
}

.availability {
	text-align: right;
	font-size: 18px;
	color: #4e5b69;
	margin-top: 3px;
}

.availability .toggle.btn-xs {
	min-width: 26px;
	width: 60px !important;
	margin-top: -15px;
}

.availability .toggle-handle.btn-xs {
	top: 1px !important;
	right: 13px;
}

.chat-head h4 {
	background: #7986cb;
}

.chat-window span.chat-begin {
	position: absolute;
	background: #7986cb;
	top: 1px;
	border-radius: 0;
	color: #fff;
	font-size: 14px;
	height: 41px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.input-wrap input {
	display: none;
}

.chat-container div[role='complementary'] {
	width: 550px !important;
	height: 500px !important;
}

.chat-container {
	bottom: 101px !important;
}

.chat-user .chat-window.card .chat-body {
	height: 400px !important;
}

.chat-user .ch-convo {
	height: 400px;
}

.chat-container > .chat-header {
	background: #7986cb !important;
	box-shadow: 0px 0px 12px 5px rgba(99, 94, 190, 0.08) !important;
	position: relative;
	height: 52px;
}

.accord-wrap .badge-success {
	color: #fff;
	background-color: #b2c8ea;
	width: 30px;
	border-radius: 50px;
	font-size: 12px;
}

.chat-window.card .bot-frame.asc-bot-v2 .ac-input {
	height: auto !important;
}

.show-section .dropdown .dropdown-toggle:after {
	top: 19px;
	color: #000 !important;
}

@media all and (max-width: 1366px) {
	.agent-chat-list span.badge-count {
		min-width: 12px;
		height: 12px;
		margin-top: 3px;
	}
	span.LinkFix {
		width: 158px;
	}
	span.time-stamp {
		font-size: 10px;
		padding-top: 5px;
	}
}

.app-body {
	background: #f0f6ff !important;
}

.Collapsible {
	background: #fff !important;
	border-bottom: 0px !important;
}

.pe-list li:hover {
	background: #f0f6ff !important;
	color: #fff !important;
}

.agent-chat-list ul.pe-list li {
	height: 46px !important;
}

span.Collapsible__trigger.is-open {
	border-bottom: 0px;
}

.active-chat-selected {
	background: #f1f1f1 !important;
	color: #fff !important;
}

.Collapsible__contentInner .pe-list {
	padding-left: 12px !important;
}

/* best hack reactselect */

.css-1pcexqc-container div.css-kj6f9i-menu div.css-11unzgr {
	height: auto !important;
	position: relative !important;
}

.availability {
	margin-top: -23px !important;
}

.chat-window.card .chat-body {
	padding: 0px;
}

.main-inner.mychat {
	background: transparent !important;
	box-shadow: none !important;
}
.main-inner.mychat .card {
	background: transparent;
	height: 100%;
}
.chat-user .ch-convo {
	height: 400px !important;
	width: 100% !important;
}
.chat-user .chat-window.card .chat-body {
	margin-bottom: 0px;
}

.teams-dark .common-heading.row .heading {
	background: #201f21 !important;
	box-shadow: none !important;
	border-bottom: 1px solid #000;
}

.teams-dark .common-heading .heading h5 {
	font-size: 18px !important;
	color: #d3d0d2 !important;
}

.teams-dark .availability {
	color: #d3d0d2 !important;
}

.teams-dark .main-section {
	background: #201f21 !important;
}

.teams-dark .main {
	background: #201f21 !important;
}

.teams-dark .card {
	background-color: #2c2c2d;
}

.teams-dark .agent-chat-list h4.view-head {
	background: #2d2c2d !important;
	color: #d3d0d2;
}

.teams-dark chat-window.card .chat-body {
	background: #2d2c2d;
	border: 1px solid #d3d0d2;
}

.teams-dark .ch-convo {
	background: #201f21;
}

.teams-dark .chat-window.card .chat-body {
	padding: 18px 19px;
	background: #2d2c2d;
}

.teams-dark .ch-convo {
	background: #2d2c2d;
}

.teams-contrast .main {
	background: #010001;
}

.teams-contrast .common-heading.row .heading {
	background: #010001 !important;
	border-bottom: 1px solid #fff;
}

.teams-contrast .common-heading .heading h5 {
	color: #fff !important;
}

.teams-contrast .availability {
	color: #fff !important;
}

.teams-dark .agent-chat-list h4.view-head {
	background: #010001 !important;
	color: #fff;
}

.teams-contrast .chat-window.card .chat-body {
	background: #010001 !important;
	border: 1px solid #d3d0d2;
}

.teams-contrast.ch-convo {
	background: #010001 !important;
}

.teams-contrast.chat-window.card .chat-body {
	padding: 18px 19px;
	background: #010001 !important;
}

.teams-contrast .ch-convo {
	background: #010001 !important;
}

.teams-contrast .card {
	background-color: #010001 !important;
	border: 1px solid #fff !important;
}

.teams-contrast .agent-chat-list h4.view-head {
	background: #010001 !important;
	color: #fff;
}

.teams-contrast .agent-chat-list h4.view-head:after {
	background: #02edff;
}

.chat-window.card .input-wrap {
	padding: 21px 0px;
	position: relative;
}

.teams-contrast span.btn.toggle-off.btn-xs.btn-warning {
	background: #000;
	color: #fff;
	border: 1px solid #fff;
}

.teams-contrast span.btn.toggle-on.btn-xs.btn-primary {
	background: #000;
	color: #fff;
	border: 1px solid #fff;
}

.teams-contrast span.toggle-handle.btn.btn-xs.btn-default {
	background: #feee01;
}

.assigned-to-rdo {
	position: absolute;
	left: 250px !important;
}

.reassign-wrapper [type='radio']:checked,
.reassign-wrapper [type='radio']:not(:checked) {
	position: static;
}

span.assigned-to-rdo-span {
	left: 5px;
	top: -2px;
	color: black;
	position: relative;
}

span.reassign-btn {
	background: gray;
	z-index: 99;
	position: absolute;
	cursor: pointer;
	padding: 4px;
	color: white;
	right: 30px;
	border-radius: 7px;
	border: 2px solid darkgray;
	top: 8px;
}

.renderResponse {
	height: 200px;
	width: 100%;
	background: #fff;
	position: absolute;
	z-index: 9999;
	right: 0;
	bottom: 55px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	transition: all 0.3s ease;
	overflow: auto;
}

.availability span.btn.toggle-on.btn-xs.btn-primary {
	background: #2ecc71;
	color: #fff;
	border: 1px solid #2ecc71;
}
.availability span.tog-switch h4 {
	font-size: 0px;
}
.availability span.btn.toggle-off.btn-xs.btn-warning {
	background: #bcbcbc;
	color: #fff;
	border: 1px solid #bcbcbc;
	padding-left: 24px;
}
.availability .toggle.btn-xs {
	min-width: 26px;
	width: 48px !important;
	margin-top: -15px;
}
.renderResponse {
	height: 200px;
	width: 100%;
	background: #fff;
	position: absolute;
	z-index: 9999;
	right: 0;
	bottom: 55px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	transition: all 0.3s ease;
	overflow: auto;
}

.renderResponse ul {
	padding: 7px 0;
}

.renderResponse li {
	color: rgb(120, 120, 120);
	font-size: 13px;
	line-height: 14px;
	position: relative;
	padding: 5px 15px;
	cursor: pointer;
}

.renderResponse li:hover {
	background-color: #f5f5f5;
	color: #484848;
}
.renderResponse li:before {
	position: absolute;
	width: 4px;
	height: 4px;
	content: '';
	background: #787878;
	border-radius: 10px;
	left: 7px;
	top: 9px;
}
.main-inner.dashboard-main {
	background: transparent;
	padding: 0px !important;
	box-shadow: none !important;
}
.dashboard-main .pb-2,
.dashboard-main .py-2 {
	background: #fff;
	margin-bottom: 14px !important;
}
.main-inner.dashboard-main h5 {
	margin-bottom: 15px;
}
.main-inner.mychat .convo-wrap.from-me.col-blue p {
	background-color: #e2e2e2 !important;
	color: #757575;
}
.main-inner.mychat .col-blue .convo-wrapper {
	background: #f5f5f5 !important;
}

.main-inner.mychat .ac-pushButton:hover {
	background: #7986cb !important;
	color: #fff !important;
	border-color: #7986cb !important;
	transition: all 0.4s;
	cursor: pointer;
}
.convo-wrap.from-me.col-blue .ac-container.ac-adaptiveCard {
	background: #e2e2e2 !important;
}
.convo-wrap.from-me.col-blue .ac-textBlock {
	font-size: 13px !important;
	color: #757575 !important;
	font-weight: 400 !important;
}
.chat-user .convo-wrap.from-me.col-blue .ac-pushButton {
	color: #7986cb;
	border-color: #7986cb !important;
}
.composite-form .custom-form-grp label {
	width: auto !important;
}
.app-header.navbar .navbar-brand img {
	width: 100% !important;
	height: auto !important;
}
.disable-chat {
	pointer-events: none;
	opacity: 0.6;
}

.mychat-teams .chat-window.card {
	height: 601px !important;
}
.mychat-teams .chat-user .chat-window.card .chat-body {
	height: 560px !important;
}

.mychat-teams .chat-user .ch-convo {
	height: 560px !important;
	width: 100% !important;
}
.profile-edit-option.views.card.agent-chat-list {
	margin-bottom: 0px;
	padding: 0 14px;
}
.profile-edit-option.views.card.agent-chat-list {
	padding-bottom: 15px !important;
}

.profile-edit-option ul.accord-row {
	max-height: 432px;
	overflow: auto;
	overflow-x: hidden;
}
.main-inner.mychat {
	padding: 0px !important;
}
.main-inner.mychat .chat-window.card {
	height: calc(100vh - 220px) !important;
}
.main-inner.mychat .pre-bot {
	height: calc(100vh - 221px) !important;
}
.main-inner.mychat .chat-user .chat-window.card .chat-body {
	height: calc(100vh - 306px) !important;
}
.main-inner.mychat .chat-user .ch-convo {
	height: calc(100vh - 306px) !important;
}

@media all and (max-width:1400px)
{
.app-footer .lead {
    left: 33% !important;
}
.app-footer span.apf-right.pull-right {
    right: 25px !important;
}

}